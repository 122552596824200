<template>
  <b-modal
    v-model="show"
    title="Media Preview"
    size="lg"
    scrollable
    @hidden="hidePreview"
    :hide-footer="true"
  >
    <b-img
      v-if="mediaPreview.mediaType === 1"
      :src="mediaPreview.path"
      fluid
    ></b-img>
    <div v-else>
      <div
        v-if="mediaPreview.path && mediaPreview.path.includes('tiktok')"
        v-html="mediaPreview.extras.detail.html"
      ></div>

      <ig-block
        v-else-if="mediaPreview.path && mediaPreview.path.includes('instagram')"
        :src="mediaPreview.path"
      ></ig-block>

      <b-embed
        v-else
        type="iframe"
        aspect="16by9"
        :src="path"
        allowfullscreen
      ></b-embed>
    </div>
  </b-modal>
</template>

<script>
import { ForceEmbedVideo, DynamicLoadScript } from "@/services/helpers";
import IgBlock from "@/components/IgBlock";
export default {
  name: "CandidateMediaPreview",
  components: {
    IgBlock,
  },
  props: {
    showMediaPreview: {
      type: Boolean,
      require: true,
      default: () => {
        return false;
      },
    },
    media: {
      type: Object,
      require: true,
      default: () => {
        return {};
      },
    },
  },
  data: () => ({
    show: false,
    path: null,
    mediaPreview: {},
  }),
  watch: {
    showMediaPreview() {
      this.show = this.showMediaPreview;
      this.mediaPreview = this.media;
      this.path = ForceEmbedVideo(this.media.path);
    },
  },
  methods: {
    hidePreview() {
      this.show = false;
      this.mediaPreview = {};
      this.$emit(`hide`);
    },
  },
  updated() {
    DynamicLoadScript("https://www.tiktok.com/embed.js");
    DynamicLoadScript("https://www.instagram.com/embed.js");
    //Reprocess instagram so it will load dynamically
    if (window.instgrm) {
      window.instgrm.Embeds.process();
    }
  },
};
</script>
